<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="listWo"
      sort-by="no"
      dense
      @click:row="onClickRow"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-text-field
                flat
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ item }">
        {{ listWo.findIndex(x => x === item) + 1 }}
      </template>
      <template v-slot:item.peopleInCharge="{ item }">
        {{ item.peopleInCharge.map(x => x.extEmployeeName).toString() }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          small
          color="success"
          @click.stop="complete(item)"
          v-if="item.status === 'OUTSTANDING'"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <dialog-complete
      :dialog="dialog"
      :item="item"
      @complete="completed"
      @close="close"
    ></dialog-complete>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogComplete from "./DialogComplete.vue";

export default {
  name: "tasks",
  components: {
    DialogComplete,
  },
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "WO#",
        value: "woNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Description",
        value: "description",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Supervisor",
        value: "supervisor",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "PIC",
        value: "peopleInCharge",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Start Date",
        value: "startDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "End Date",
        value: "endDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Duration(minutes)",
        value: "durationMins",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "Source",
        value: "appSource",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 9,
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    item: {},
    taskId: null,
    dialog: false,
    search: null,
  }),

  computed: {
    ...mapState("wo", ["listWo"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("wo/getTasks")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Wo", params: { id: item.woNumber } });
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    async complete(item) {
      await this.$store
        .dispatch("wo/getById", item.woNumber)
        .then(response => {
          this.item = response.data;
          this.taskId = item.id;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
        });
    },
    async completed(item) {
      item.tasks.forEach(x => {
        if (x.id === this.taskId) {
          x.status = "COMPLETED";
        }
      });

      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("wo/update", item)
        .then(() => {
          this.dialog = false;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
